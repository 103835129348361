@import '../../../styles/variables';
.root {
  display: flex;
  background-color: $lightGray;
  padding: 30px;

  align-items: center;
  margin-bottom: 30px;


  ._image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    max-width: 100px;
    min-width: 40px;
    margin-right: 10%;

    img {
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  ._info-wrapper {
    display: flex;
    flex-direction: column;
    h2 {
      font-weight: 300;
      font-size: 25px;
      line-height: 34px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 155%;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;

        height: 1px;
        width: 100%;

        background-color: black;
      }
    }

    a {
      text-decoration: none;
    }
  }


  @media screen and (max-width: $desktopBreakpoint) {
    
    

    ._image-wrapper {
      width: 40px;
    }

    ._info-wrapper {
      align-items: flex-start;
      justify-content: flex-start;
      h2 {
        text-align: left;
        font-size: 20px;
        margin: 0;
      }

      a, p {
        font-size: 14px;
      }
    }

  }
}
