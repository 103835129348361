@import '../../../styles/variables';
.root {
  --color: var(--theme-button-color);
  --background-color: var(--theme-button-background);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 1.4rem;
  font-weight: 500;
  @include font-xsmall;
  letter-spacing: 0.2em;
  text-decoration: none;
  cursor: pointer;
  transition: all 250ms ease-out;
  text-transform: uppercase;
  color: var(--color);
  background-color: var(--background-color);
  transition: background-color 150ms ease-out, color 200ms ease-out;
  border: var(--border);

  path {
    stroke: var(--color);
  }

  &:hover {
    color: var(--hover-color);
    background-color: var(--background-hover-color);
    path {
      stroke: var(--hover-color);
    }
  }

  a {
    text-decoration: inherit;
    letter-spacing: 0.2em;
  }
}

.active {
  color: var(--hover-color);
  background-color: var(--background-hover-color);
  path {
    stroke: var(--hover-color);
  }
}
