.media {
  position: absolute;
  // z-index: -1;
  top: 0;
  left: 50%;
  // A little bigger (101%) to handle half pixel issues
  min-width: 101%;
  height: 101%;
  transform: translateX(-50%);
  overflow: hidden;
  @media screen and (min-width: 1000px) {
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }

  img {
    object-fit: cover;
    object-position: center center;
  }

  .videoWrapper {
    height: 100%;

    [class='react-player'] {
      width: auto;
      height: 100%;
      object-fit: cover;

      video {
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}
