@import '../../../styles/variables';

.root {
  display: grid;
  color: white;
  font-weight: bold;
  pointer-events: none;

  @include fullWidth_45_55;
  @include full-width;

  @media screen and (max-width: $mapDesktopBreakpoint) {
    @include fullWidth_45_55_columns;
  }

  &:not(.activePage) {
    @media screen and (min-width: $mapDesktopBreakpoint) {
      height: calc(100vh - 70px);
    }
  }
}

.mapContainerWrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media screen and (max-width: $mapDesktopBreakpoint) {
    grid-row-start: 1;
    overflow: hidden;
    height: 30vh;
  }
}

.mapContainer {
  flex: 1;
  min-height: calc(100vh - 70px);
  height: 0;

  @media screen and (max-width: $mapDesktopBreakpoint) {
    min-height: 100%;
    height: 100%;
  }

  > div {
    position: flex;
    height: 100%;
    max-height: 100vh;

    canvas {
      pointer-events: all;
    }
  }

  &._with-details {
    flex: 0;
    min-height: 40vh;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 45vh;
  color: black;
  font-size: 50px;
}
