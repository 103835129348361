@import '../../../styles/variables';
.root {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  height: 100%;
  width: 100%;
  a {
    display: flex;
    align-items: center;
    font: inherit;
    height: 100%;
    line-height: 100%;
    &:before {
      opacity: 0.1;
      content: '';
      position: absolute;
      width: 100px;
      height: 50px;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &.box {
    max-width: 150px;
    margin-top: 30px;
  }
}

.box {
  transition: all 250 ease-in-out;
  max-width: 150px;
  margin-top: 30px;
  color: white;
  background-color: var(--theme-button-background);
  border: 1px solid var(--theme-button-background);
  height: 45px;

  &:hover {
    color: black;
    background-color: white;
  }
}
