._image,
._prev-image,
._next-image,
._prev-image-gutter {
  width: 100%;
  height: 100%;

  // padding: 0 10px;

  > span {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 70%;
    height: 100%;
    width: 100%;
  }

  video {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
  }
}

._prev-image {
  position: absolute;
  left: calc(-100% - 60px);
}

._prev-image-gutter {
  position: absolute;
  left: calc(-200% - 120px);
}

._next-image {
  position: absolute;
  left: calc(100% + 60px);
  z-index: 1;
}

._hidden {
  display: none;
}
