@import '../../../styles/variables';

.root {
  position: relative;

  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  overflow: hidden;

  background-color: rgba(255, 255, 255, 0.5);

  @include full-width;
  height: calc(100vh - 70px);
  min-width: 320px;

  @media screen and (max-width: $desktopBreakpoint) {
    min-width: 320px;
  }

  // @include twoColumnLayout_10bar_5bar_space;
  // @include largePaddingY;

  ._wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // max-width: 960px;
    // min-height: 540px;
    // max-height: 960px;

    justify-self: flex-start;
    align-self: flex-start;

    ._content {
      flex: 1;
      background-color: white;

      display: grid;
      grid-template-rows: auto;
      grid-template-columns: minmax(300px, 33%) auto;

      @media screen and (min-width: $desktopBreakpoint) {
        grid-template-columns: minmax(450px, 33%) auto;
      }

      @media screen and (max-width: $desktopBreakpoint) {
        grid-template-columns: auto;
      }

      height: calc(100% - 70px);

      ._configuration {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: minmax(200px, 40%) auto;

        // overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;

        ._desc {
          padding: 10%;

          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            font-family: 'Open Sans';
            font-weight: 300;
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 15px;
          }

          p {
            font-family: 'Open Sans';
            font-weight: 300;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }

    ._footer {
      width: 100%;
      height: 70px;

      z-index: 10;

      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto minmax(auto, 100px);

      background-color: #d7dfe3;

      @media screen and (max-width: $desktopBreakpoint) {
        grid-template-columns: auto;

        ._current-step {
          display: none !important;
        }
      }

      ._information-container-wrapper {
        position: relative;

        ._header-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
          width: 100%;
          transform: translate3d(0, 0, 0);

          background-color: #d7dfe3;

          overflow: hidden;

          &.open {
            transform: translate3d(0, calc(-100% + 70px), 0);

            ._information-container {
              ._selections {
                svg {
                  transform: rotate(90deg);
                }
              }
              ._information {
                transition: opacity 250ms ease-out;
                opacity: 0;
              }
            }
          }

          ._information-container {
            height: 70px;

            display: grid;
            grid-template-rows: auto;
            grid-template-columns: minmax(150px, calc(33% + 33px)) minmax(200px, auto);

            @media screen and (max-width: 414px) {
              grid-template-columns: minmax(100px, calc(33% + 33px)) minmax(200px, auto);
            }

            ._information {
              display: grid;
              grid-template-rows: auto;
              grid-template-columns: minmax(160px, 80%) minmax(100px, auto);
              opacity: 1;

              transition: opacity 250ms ease-in;

              > div {
                margin-left: 12%;
              }

              ._total-cost {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-indent: 12%;

                h4 {
                  font-family: 'Open Sans';
                  font-weight: 600;
                  font-size: 10px;
                  line-height: 14px;
                  letter-spacing: 0.08em;
                }

                > div {
                  font-family: 'Open Sans';
                  font-size: 14px;
                  line-height: 19px;
                }
              }

              button {
                background-color: transparent;
                padding-top: 15px;
              }
            }
            ._selections {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              padding: 0 4%;

              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 11px;
              line-height: 15px;
              text-transform: uppercase;
              h3 {
                opacity: 0.5;
                margin-right: 15px;
              }

              svg {
                transform: rotate(-90deg);
              }
            }
          }

          ._info {
            position: relative;
            min-height: 200px;
            width: 100%;

            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 140px);

            // TODO: fix propperly
            padding: 0 4%;

            ._button {
              position: relative;
              display: inline-block !important;
              margin-top: 30px !important;
              background-color: pink;
            }

            ._cost_total {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              width: 90vw;
              max-width: 560px;
              min-height: 80px;
              height: 20%;
              max-height: 100px;

              @media screen and (max-width: 414px) {
                width: 90vw;
              }

              h5 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
              }

              p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
              }
            }

            h3 {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              line-height: 27px;

              padding-top: 20px;
              margin-bottom: 20px;
            }

            .selections {
              display: flex;
              flex-direction: column;
            }

            ._disclamer {
              display: block;
              margin: 40px 0;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              line-height: 16px;
              margin: 30px 40px 0 0;
              color: rgba(0, 0, 0, 0.6);
              max-width: 640px;
              margin-bottom: 20px;

              p {
                span {
                  color: rgba(0, 0, 0, 0.6);
                }
              }
            }
          }
        }
      }

      ._current-step {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        text-transform: uppercase;

        color: #000000;

        opacity: 0.5;

        &:after {
          content: '';
          position: absolute;
          width: 2px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: white;
        }
      }
    }
  }
}
