@import '../../../../styles/variables';

.root {
  position: relative;
  display: flex;
  min-height: 65vh;
  justify-content: center;
  align-items: center;
  @include paddingY;
  width: 100%;
  background-color: $lightGray;
  color: black;
  @media screen and (max-width: $desktopBreakpoint) {
    padding-top: $componentYPadding;
    padding-bottom: $componentYPadding;
  }
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate3d(-50%, 0, 0);
    background-color: inherit;
    width: 100vw;
    height: 100%;
  }
  > div,
  > button {
    z-index: 1;
  }
}

.container {
  text-align: center;
  max-width: 640px;
  h2 {
    padding-bottom: 0.5rem;
  }
  // ANIM
  > h2,
  > p {
    transition: opacity 350ms ease-out, transform 400ms ease-out;
    transform: translate3d(0, -10px, 0);
    opacity: 0;
  }
  > p {
    transition-delay: 100ms;
  }
  > form {
    span,
    input,
    div {
      transition: opacity 350ms 250ms ease-out, transform 400ms ease-out;
      transform: translate3d(0, -10px, 0);
      opacity: 0;
    }
  }
  .in-view & {
    > h2,
    > p {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    > form {
      span,
      input,
      div {
        @for $i from 1 through 10 {
          &:nth-child(#{$i}n) {
            transition-delay: #{$i * 75}ms;
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
  // ANIM END
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 420px;
  padding-top: 60px;
  @media screen and (max-width: $desktopBreakpoint) {
    min-width: unset;
    padding-top: $componentYPadding;
    padding-bottom: $componentYPadding;
  }
  select,
  ::placeholder,
  input,
  label {
    font-size: 16px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    border: 0;
  }
  select,
  input {
    padding: 23px 26px;
    margin-bottom: 14px;
    text-align: left;
  }
}

.privacy {
  opacity: 0.5;
  > p {
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 32px;
    align-self: flex-start;
    text-align: left;
  }
  a {
    transition: all 250ms ease-out;
  }
}

.privacy:hover {
  opacity: 0.7;
  a {
    color: $lightBlue;
  }
}

.checkbox {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  input[type='checkbox'] {
    height: 26px;
    width: 26px;
    margin: 0 $fontSize 0 0;
    padding: 0;
    background: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 2px;
  }
  input[type='checkbox']:checked {
    background: black;
  }
}

.submitBtn {
  width: 116px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $fontSize;
  margin-top: $padding;
  font-weight: 600;
  font-size: $fontSize;
  line-height: $fontSize;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: black;
  color: white;
}
