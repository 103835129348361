@import '../../../styles/variables';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > span {
    color: inherit;
    margin-right: 10px;
    font-size: $fontSize;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }

  &.white {
    &:hover {
      > svg {
        fill: white;
        circle {
          stroke: black;
        }

        g {
          fill: black;
        }
      }
    }
  }
  &.black {
    &:hover {
      > svg {
        fill: black;
        circle {
          stroke: white;
        }

        g {
          fill: white;
        }
      }
    }
  }
}
