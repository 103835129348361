@import '../../../styles/variables';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  height: 30px;
  padding: 0 15px;
  min-width: 10px;
  margin: 0 5px;

  border-radius: 15px;

  border: 1px solid black;

  transition: background-color 250ms ease, color 300ms ease-out;

  svg {
    margin-right: 6px;

    path {
      transition: fill 250ms ease, color 300ms ease-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: white;
      }
    }
  }
}

.active {
  background-color: black;
  color: white;
}

.inactive {
  background-color: white;
  color: black;
}