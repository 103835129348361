.root {
  width: 100%;
  > div {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: all 250ms ease-out;
  }

  &.in-view {
    > div {
      @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
          transition-delay: #{$i * 75}ms;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  img {
    width: 100%;
    height: 20vh;
    max-height: 300px;
    object-fit: cover;
  }

  .desc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;

    margin-top: 10px;
  }

  ._checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 20px;

    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      .name {
        text-transform: uppercase;
        margin-left: 10px;
      }
    }

    > div {
      font-family: 'Open Sans';
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.13em;
      text-transform: uppercase;
      margin-right: 15px;
    }
  }
}
