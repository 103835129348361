@import '../../../../../styles/variables';
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  color: black;
  h3 {
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  ul {
    margin-top: 10px;

    li {
      &:hover {
        cursor: pointer;
        background-color: rgba($hoverGray, 0.15);
      }
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  ._box,
  ._box-top {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 60px;
    align-items: center;
    padding: 0 10px;
    overflow: hidden;
    ._top {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background-color: black;
    }
  }
  ._box-top {
    justify-content: center;
  }
  ._box {
    height: 60px;
    max-height: 60px;
    justify-content: flex-start;
    transition: max-height 250ms ease-out;
    svg {
      transition: margin-top 150ms ease-out;
    }
    &.--active {
      transition: max-height 1250ms ease-out;
      height: auto;
      background-color: black;
      color: white;
      max-height: 600px;
      padding: 0 35px;
      &:hover {
        svg {
          margin-top: 0;
        }
      }
    }
    &:hover {
      svg {
        margin-top: 10px;
      }
    }
    ._information {
      width: 100%;
      padding: 10px 0 25px 0;
      display: flex;
      flex-direction: column;
      h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
      h3 {
        margin-bottom: 1rem;
      }

      a {
        width: fit-content;
        text-decoration: none;
        transition: all 250ms ease-out;
        position: relative;

        &:before,
        &:after {
          left: 0;
          content: '';
          position: absolute;
          bottom: -1px;
          width: 0;
          height: 1px;
          margin: 5px 0 0;
          transition: all 250ms ease-out;
          transition-duration: 0.55s;
          opacity: 0;
          background-color: white;
        }
      }
      a:hover {
        cursor: pointer;
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
      a:first-of-type {
        margin: 0 0 2rem 0;
        color: $lightBlue;
        &:before,
        &:after {
          background-color: $lightBlue;
        }
      }
      a:last-of-type {
        margin-bottom: 2rem;
      }
      a[href^='tel:'] {
        color: white;
        text-decoration: none;
      }
      a[href^='mailto:'] {
        color: white;
        text-decoration: none;
      }
    }
  }
}
