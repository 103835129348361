.root {
  > div {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: all 250ms ease-out;
  }

  &.in-view {
    > div {
      @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
          transition-delay: #{$i * 75}ms;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  .model {
    width: 100%;
    min-height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 24px;

    margin: 13px 0;

    ._header-wrapper {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: minmax(20px, 10%) minmax(50px, auto);
      align-items: center;

      ._header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.13em;
          text-transform: uppercase;
        }

        > div {
          font-family: 'Open Sans';
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.13em;
        }
      }
    }

    h5,
    p,
    ul {
      font-family: 'Open Sans' !important;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px !important;
      letter-spacing: 0.13em;

      > li {
        list-style: none;
        font-size: 14px;
        padding-bottom: 0;
        &:before {
          content: '-';
          position: absolute;
          left: 15px;
        }
      }
    }

    h5 {
      margin-top: 24px;
      text-transform: uppercase;
    }

    p,
    ul {
      margin-top: 2px;
    }

    button {
      margin-top: 10px;
      background-color: transparent;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-decoration-line: underline;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
