@import '../../../../styles/variables';
.root {
  padding: 0 10%;

  cursor: pointer;

  grid-template-rows: 70px auto;
  grid-template-columns: auto;

  ._header--wrapper {
    position: relative;

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(100px, 80%) minmax(50px, auto);
    align-items: center;

    height: 10vh;
    width: 100%;
    min-height: 70px;
    max-height: 80px;

    &._open {
      ._header-info-icon {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &:hover {
      background-color: #fafafa;

      &:before {
        content: '';
        position: absolute;
        width: 130%;
        height: 100%;
        left: -15%;
        background-color: #fafafa;
        z-index: 0;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 130%;
      height: 1px;
      background-color: black;

      top: 0;
      left: -15%;
    }

    ._header-info-wrapper {
      position: relative;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.08em;
      }

      > div {
        font-family: 'Open Sans';
        font-weight: 300;
        font-size: 20px;
        line-height: 27px;
      }
    }

    ._header-info-icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 2;
    }
  }

  &:last-child {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: black;

      bottom: 0;
      left: -15%;
    }
  }

  ._content {
    display: none;

    margin-bottom: 60px;
    &._open {
      display: flex;
      flex-direction: column;

      > span {
        margin-top: 30px;
        max-width: 150px;
      }
    }
  }
}
