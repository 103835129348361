@import '../../../styles/variables';
.root {
  height: 100%;
  width: 100%;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, var(--opacity));
  }
}
