.root {
  width: 23px;
  height: 23px;

  background-color: white;
  border: 1px solid black;

  box-sizing: border-box;

  &.active {
    background-color: #27a3f2;
  }

  margin-right: 15px;
}
