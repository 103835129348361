@import '../../../styles/variables';
.videoWrapper {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  position: relative;
  display: block;
  // object-fit: cover;
  height: 100%;
  min-width: 100%;
  min-height: 100%;

  [class='react-player'] {
    position: absolute;
    inset: 0;
    object-fit: cover;

    height: 100%;
    video,
    iframe {
      // opacity: 0.6;
      // min-height: 100%;
      // min-width: 100%;
      transform: scale(1.2);
      width: auto;
      object-fit: cover;
      object-position: center center;
    }
  }
}
// .CarHero {
//   width: 100%;
//   color: var(--theme-color);
//   position: relative;
//   overflow: hidden;

//   .content {
//     // aspect-ratio: 4/7;
//     min-height: 60vh;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     @include breakpoint($desktopBreakpoint) {
//       aspect-ratio: 16/7;
//       min-height: auto;
//     }

//     @include breakpoint(1800px) {
//       aspect-ratio: 16/7;
//     }
//   }
//   .videoWrapper {
//     // position: absolute;
//     // top: 50%;
//     // left: 50%;
//     // transform: translate(-50%, -50%);
//     position: relative;
//     display: block;
//     // object-fit: cover;
//     height: 100%;
//     min-width: 100%;
//     min-height: 100%;

//     [class='react-player'] {
//       position: absolute;
//       inset: 0;
//       object-fit: cover;

//       background-color: pink;

//       height: 100%;
//       video,
//       iframe {
//         // opacity: 0.6;
//         // min-height: 100%;
//         // min-width: 100%;
//         transform: scale(1.2);
//         width: auto;
//         object-fit: cover;
//         object-position: center center;
//       }
//     }
//   }

//   ._header {
//     display: flex;
//     padding-top: var(--spacing-xlarge);
//     flex-direction: column;
//     width: 100%;
//     align-items: center;
//     text-align: center;
//   }

//   .subtitle {
//     margin-bottom: var(--spacing-xsmall);
//   }

//   .price {
//     margin-top: var(--spacing-medium);
//   }

//   .mobileSpecs {
//     isolation: isolate;
//     @include breakpoint($desktopBreakpoint) {
//       display: none;
//     }
//   }

//   .desktopSpecs {
//     display: none;
//     // Compensating for half pixels
//     transform: translateY(1px);
//     @include breakpoint($desktopBreakpoint) {
//       display: block;
//     }
//   }

//   ._specs {
//     background: var(--theme-background);

//     @include breakpoint($desktopBreakpoint) {
//       background: rgba(var(--background), 0.6);
//       display: flex;
//       justify-content: center;
//     }
//   }

//   ._specs-inner {
//     padding-top: var(--spacing-gutter);
//     padding-bottom: var(--spacing-gutter);
//     display: flex;
//     flex-direction: column;
//     gap: var(--spacing-medium);

//     @include breakpoint($desktopBreakpoint) {
//       flex-direction: row;
//       flex-wrap: wrap;
//       flex-direction: row;
//       justify-content: space-between;
//       padding-top: var(--spacing-large);
//       padding-bottom: var(--spacing-large);
//     }
//   }

//   .spec {
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     gap: var(--spacing-xsmall);

//     @include breakpoint($desktopBreakpoint) {
//       gap: var(--spacing-small);
//     }
//   }

//   .spec_title {
//     @include font-small;
//     @include smallcaps;
//     @include breakpoint($hugeBreakpoint) {
//       @include font-body;
//     }
//   }
//   .spec_desc {
//     @include font-small;
//     @include breakpoint($hugeBreakpoint) {
//       @include font-body;
//     }
//   }

//   .actions {
//     background: var(--theme-background);
//     position: relative;
//   }
//   .actions-inner {
//     @include container;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: var(--spacing-gutter);
//     padding-top: var(--spacing-gutter);
//     padding-bottom: var(--spacing-gutter);

//     @include breakpoint($desktopBreakpoint) {
//       flex-direction: row;
//       padding-top: var(--spacing-large);
//       padding-bottom: var(--spacing-large);
//     }
//   }
// }
