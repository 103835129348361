@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: $fontSize;

  > span {
    font-weight: 600;
    @include font-xsmall;
    letter-spacing: 0.2em;
  }

  a {
    letter-spacing: 0.2em;
    text-decoration: none;
  }

  svg {
    transform: translate3d(10px, 1px, 0) !important;
    transition: transform 150ms ease-out;
    min-height: 6px;
    min-width: 7px;
  }

  &:hover {
    svg {
      transform: translate3d(15px, 1px, 0) !important;
    }
  }

  &._reverced {
    flex-direction: row-reverse;
    justify-content: flex-end;
    svg {
      transform: scale(-1) translate3d(5px, 0px, 0) !important;
    }

    &:hover {
      svg {
        transform: scale(-1) translate3d(10px, 0px, 0) !important;
      }
    }
  }
}
