.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // text-indent: 12%;

  h4 {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.08em;
  }

  > div {
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap;
  }
}
