.root {
  width: 16px;
  height: 16px;

  background-color: white;
  border: 1px solid black;

  box-sizing: border-box;

  border-radius: 8px;

  &.active {
    border: none;
    background-color: #27a3f2;
  }
}
