.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;

  width: 90vw;
  max-width: 560px;

  min-height: 50px;
  height: 10vh;
  max-height: 70px;

  margin-top: 10px;
  margin-bottom: 0px;

  @media screen and (max-width: 414px) {
    width: 90vw;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -10%;
    left: -10%;
    height: 1px;
    width: 110%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  > div {
    display: flex;
    flex-direction: column;
  }

  h5 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
  }

  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
  }

  > div {
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 19px;
  }
}
