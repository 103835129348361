@import '../../../styles/variables';

.container {
  position: relative;
  height: 90vh;
  @include full-width;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > div {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: left;

    align-self: center;
    justify-self: center;
    flex-direction: column;

    min-width: 663px;
    @include max-plug-width;

    margin-left: auto;
    margin-right: auto;

    bottom: 100px;
    left: 0;
    right: 0;

    @media screen and (max-width: $desktopBreakpoint) {
      width: 90vw;
      align-items: center;
      min-width: 0;
      max-width: 90vw;
    }

    @media screen and (max-width: $smallPhoneBreakpoint) {
      width: 90vw;
    }

    h4,
    p,
    form {
      margin-left: calc($oneColumnWidth + $contentColumnSpace * 2);
      @media screen and (max-width: $desktopBreakpoint) {
        margin-left: 0;
      }
    }

    h4 {
      font-family: 'open sans';
      margin-bottom: 20px;
      text-align: left;
      width: 100%;
      font-weight: 600;
      font-size: 1rem;
      line-height: 22px;
      letter-spacing: 13%;
      color: white;
      text-transform: uppercase;

      @media screen and (min-width: $desktopBreakpoint) {
        width: 50%;
        min-width: 400px;
      }
    }

    p {
      font-family: 'open sans';
      margin-bottom: 60px;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      color: white;

      @media screen and (min-width: $desktopBreakpoint) {
        width: 40%;
        min-width: 400px;
      }
    }
  }

  > div > form {
    display: flex;
    position: relative;

    width: 100%;
    font-size: 30px;
    line-height: 36px;
    padding: 30px 0 30px 30px;
    text-decoration: none;
    height: 96px;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $desktopBreakpoint) {
      width: 50%;
      min-width: 420px;
    }

    input {
      width: 100%;
      background-color: transparent;
      top: 0;
      left: 0;
      height: 96px;
      line-height: 100%;
      font-size: 30px;
      line-height: 36px;

      border-bottom: 1px solid white;
    }

    button {
      width: 50px;
      height: 96px;
      background-color: black;

      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1em;

      margin-left: 10px;
      margin-right: 10px;

      svg {
        height: 50%;
        width: 70%;
      }
    }

    align-items: center;

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
  }

  > form {
    span,
    input,
    div {
      transition: opacity 350ms 250ms ease-out, transform 400ms ease-out;
      opacity: 1;
    }
  }
}

.searchLink {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  width: 663px;
  color: white;
  font-size: 30px;
  line-height: 36px;
  text-decoration: none;
  padding: 30px;

  @media screen and (max-width: $desktopBreakpoint) {
    font-size: 16px;
    width: 300px;
    padding: 16px;
  }

  @media screen and (max-width: $smallPhoneBreakpoint) {
    font-size: 12px;
    width: 280px;
    padding: 14px;
  }
}
