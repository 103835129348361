@import '../../../styles/variables';
.root {
  display: flex;
  flex-direction: column;
  max-width: $eightColumnWidth;
  @include paddingY;

  color: black;

  > h4,
  > p {
    transition: opacity 350ms ease-out, transform 400ms ease-out;
    transform: translate3d(0, -10px, 0);
    opacity: 0;
    transition-delay: 200ms;
    width: 100%;
  }
  > 
    p {
      transition: opacity 350ms ease-out, transform 400ms ease-out;
      transform: translate3d(0, -10px, 0);
      opacity: 0;
      transition-delay: 200ms;
      padding: 0 0 1.5rem;
    }
  

  &.in-view {
    > h4 {
      transform: translate3d(0, 0, 0);

      @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
          transition-delay: #{$i * 75}ms;
          opacity: 1;
        }
      }
    }

    > p {
      opacity: 1;
    }

    > 
      p {
        @for $i from 1 through 10 {
          &:nth-child(#{$i}n) {
            transition-delay: #{10 + $i * 75}ms;
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      
    }
  }

  h4 {
    margin-bottom: 3vh;
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 46px;
    text-align: left;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 180%;
    text-align: left;
  }

  @media screen and (max-width: $desktopBreakpoint) {
    max-width: 95%;
  }
}

.center {
  max-width: unset;
  align-items: center;
  text-align: center;

  h4 {
    width: 100%;
    text-align: left;
  }
}

.centerText {
  h4 {
    text-align: center;
  }

  p {
    text-align: center;
  }
}
