// COLORS
$dark: rgba(0, 0, 0, 1);
$dark-text: $dark;
$lightGray: #e3e8eb;
$gray: #c4c4c4;
$lightBlue: #66abd7;
$hoverBlue: #5188ac;
$dottBLue: #28c6f8;
$hoverGray: #94b3c8;
$textGray: #9d9d9d;

// hover white black btns
$hoverBlack: #2d2d2d;
$hoverWhite: #cccccc;
