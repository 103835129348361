@import '../../../../../styles/variables';
.root {
  width: 100%;
  > div {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: all 250ms ease-out;
  }

  &.in-view {
    > div {
      @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
          transition-delay: #{$i * 75}ms;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  .color {
    width: 100%;
    min-height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 24px;

    margin: 13px 0;

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(100px, auto) minmax(100px, auto);
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 400px) {
      padding: 12px;
    }

    ._header-wrapper {
      display: grid;
      // flex-direction: row;
      align-items: center;
      grid-template-rows: auto;
      grid-template-columns: 20px minmax(60px, auto) auto;
      justify-content: space-between;
      align-items: center;
      grid-column-gap: 10px;

      @media screen and (max-width: 400px) {
        grid-template-columns: 20px 40px auto;
        grid-column-gap: 5px;
      }

      > div {
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.13em;
        text-transform: uppercase;
      }

      img {
        width: 60px;

        @media screen and (max-width: 400px) {
          width: 40px;
        }
      }
    }

    h5,
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.13em;
    }

    h5 {
      text-transform: uppercase;
      text-align: right;
    }

    p {
      margin-top: 2px;
    }

    button {
      margin-top: 10px;
      text-decoration: underline;
    }
  }
}
