.root {
  background-color: #000000a8;
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;

  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 20;

  ._wrapper {
    display: flex;
    flex-direction: column;

    width: 90vw;
    max-width: 1024px;
    height: calc(80vh - 70px);

    background-color: white;

    ._header-content {
      display: flex;
      height: 60px;

      align-items: flex-end;
      justify-content: flex-end;

      ._close {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 60px;
        cursor: pointer;
        box-sizing: border-box;

        background-color: white;

        border-left: 1px solid black;
        border-bottom: 1px solid black;

        transition: background-color 150ms ease-out;

        svg {
          path {
            transition: fill 150ms ease-out;
          }
        }

        &:hover {
          background-color: black;
          color: white;
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }

    ._content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 10%;
      overflow-x: hidden;
      overflow-y: auto;

      > div {
        width: 80%;
        min-width: 300px;
        max-width: 768px;

        font-family: 'Open Sans';
        font-style: normal;

        margin: 20px 0;
      }

      ._header {
        font-weight: normal;
        font-size: 26px;
        line-height: 35px;
        text-align: center;

        color: rgba(0, 0, 0, 0.8);
      }

      ._sub-title {
        font-weight: 300;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
      }

      ._price {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.13em;
        text-align: center;
      }

      ._info {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.13em;
        width: 70%;
        max-width: 650px;

        ul {
          margin: 0;
          li {
            position: relative;
            list-style: none;
            list-style-type: none;
            padding-bottom: 10px;
            font-family: 'Open Sans';
          }
        }

        ul > li {
          text-indent: -5px;
        }
        ul > li:before {
          content: '-';
          position: absolute;
          left: -10px;
        }
      }

      ._disclamer {
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.5);
      }

      ._buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        > span {
          margin: 30px 0 20px 0;
        }

        > button {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
