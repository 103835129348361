@import '../../../../styles/variables';

.root {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;

  z-index: -1;

  @media screen and (max-width: $desktopBreakpoint) {
    height: 100%;
  }

  ._image,
  ._prev-image,
  ._prev-image_gutter,
  ._next-image,
  ._next-image_gutter {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 320px) {
      background-position: 60% center;
    }
  }

  ._prev-image {
    position: absolute;
    left: calc(-100% + -60px);
  }

  ._prev-image_gutter {
    position: absolute;
    left: calc(-200% + -120px);
  }

  ._next-image {
    position: absolute;
    left: calc(100% + 60px);
  }
  ._next-image_gutter {
    position: absolute;
    left: calc(200% + 120px);
  }
}
