@import '../../../styles/variables';

.root {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;

  @include oneColumnLayout;
  @include full-width;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media screen and (max-width: 320px) {
    background-position: 70% center;
  }
}
