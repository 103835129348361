// @import '../../../styles/variables';
// .root {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   // temporary remove because the sections in the model accordion collapse
//   // min-height: 10rem;
//   > div {
//     z-index: 1;
//   }
// }
// .background-color {
//   &:before {
//     content: '';
//     position: absolute;
//     left: 50%;
//     top: 0;
//     transform: translate3d(-50%, 0, 0);
//     background-color: inherit;
//     width: 100vw;
//     height: 100%;
//   }
// }
@import '../../../styles/variables';
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-repeat: no-repeat;
  &[class*='pricelist'] {
    background: black;
  }

  &:first-of-type {
    margin-top: 70px;
    background-color: pink;
    transform: scale(0.1);
  }

  // &:nth-last-child(2) {
  //     >div {
  //         >div {
  //             >div {
  //                 padding-bottom: 300px;
  //             }
  //         }
  //     }
  // }
  .SC__debug & {
    border: 1px solid pink;
    //
  }
}
