@import '../../../styles/variables';

.root {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  touch-action: none;
  transform: translate3d(0, 0, 0) scale3d(0.7, 0.7, 0.7);
  // transform: scale(0.7);
  z-index: 1;
  .cover {
    position: absolute;
    width: 50vw;
    height: 130%;
    background-color: black;
    left: 100%;

    @media screen and (max-width: $desktopBreakpoint) {
      height: 100%;
    }

    z-index: 10;
  }

  ._left,
  ._right {
    height: 100%;
  }

  ._arrow {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;

    top: 50%;
    right: calc(-10% + -20px);

    @media screen and (max-width: $desktopBreakpoint) {
      display: none;
    }

    svg,
    path {
      transition: all 250ms ease-out;
    }

    svg {
      fill: black !important;
    }
    path {
      fill: white !important;
    }

    &:hover {
      svg {
        fill: white !important;
      }
      path {
        fill: black !important;
      }
    }
  }
}
