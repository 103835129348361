@import '../../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;

  background-color: white;

  min-height: 300px;
  height: auto;

  @media screen and (max-width: $desktopBreakpoint) {
    min-height: 230px;
  }

  color: black;

  align-items: flex-start;
  justify-content: center;

  h2 {
    font-weight: 300;
    font-size: 25px;
    line-height: 34px;

    margin-bottom: 20px;
  }

  ._input-wrapper {
    position: relative;
    width: 100%;
    height: 75px;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    // z-index: 10;

    top: 0;

    input {
      height: 100%;
      width: 100%;
      background-color: black;
      color: white;
      text-indent: 20px;

      font-weight: 300;
      font-size: 18px;
      line-height: 25px;

      pointer-events: all;

      &::placeholder {
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
      }
    }

    svg {
      position: absolute;
      right: 20px;
      top: 29px;
    }
  }

  ._buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;

    margin-top: 20px;

    pointer-events: all;

    @media screen and (max-width: $desktopBreakpoint) {
      flex-direction: column;
      align-items: self-start;

      ._locate-button {
        margin-top: 10px;
      }
    }

    > span {
      display: flex;
    }
  }

  &.fixed-search {
    ._input-wrapper {
      position: fixed;
      top: 70px;
    }

    ._buttons-container {
      margin-top: 95px;
    }
  }
}
