@import '../../../../styles/variables';
.root {
  position: relative;
  background-color: black;
  overflow: hidden;

  img {
    object-fit: cover;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: $desktopBreakpoint) {
    display: none;
  }

  ._navigation {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 40px;
    z-index: 1;
    transition: transform 75ms ease-out;
    // transform-origin: 50% 55%;

    &:hover {
      transform: rotate(90deg);
      transition: transform 75ms ease-in;
    }
  }

  ._image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  }
}
