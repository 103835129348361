@import '../../../styles/variables';
.root {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  input {
    box-sizing: border-box;
    border: 1px solid black;
    width: 100%;
  }
  hr {
    margin: 0;
  }
  ._icon {
    display: block;
    --size: var(--font-small);
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: 50%;
    right: var(--spacing-small);
    transform: translateY(-50%);
    pointer-events: none;
    svg {
      transform: rotate(90deg);
    }
  }

  .search-result-wrapper {
    position: absolute;
    left: 0;
    top: 100px;
    background-color: white;
    width: 100%;
    padding-top: var(--spacing-small);

    z-index: 1;

    > div {
      position: relative;
      margin-bottom: 10px;

      h3 {
        text-align: left;
        padding-left: var(--spacing-small);
        font-size: var(--font-small);
        line-height: var(--font-small-leading);
        padding-bottom: var(--spacing-small);
      }

      ul {
        li {
          height: 60px;
          display: flex;
          align-items: center;

          padding: var(--spacing-small);
          box-sizing: border-box;

          &:hover {
            color: white;
            background-color: $hoverBlack;
            cursor: pointer;
          }

          h4 {
            letter-spacing: 0.08em;
            text-align: left;
          }

          p {
            font-size: 12px;
            text-align: left;
          }
        }
      }
    }
  }
}
