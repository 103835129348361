.root {
  width: 100%;
  > div,
  span {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: all 250ms ease-out;
  }

  &.in-view {
    > div,
    span {
      @for $i from 1 through 10 {
        &:nth-child(#{$i}n) {
          transition-delay: #{$i * 75}ms;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  .desc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
  }

  .alternatives {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;

    margin: 15px 0 15px;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .disclamer {
    > p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;

      color: #000000;

      opacity: 0.7;
    }
  }
}
