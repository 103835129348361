@import '../../../../styles/variables';
.root {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  background-color: white;
  min-height: 300px;
  width: 100%;
  color: black;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: $contentColumnWidth * 2;
  ._information {
    color: black;
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 250px;
    max-width: 640px;
   
    @media screen and (min-width: $desktopBreakpoint) {
      margin-top: 10vh;
    }

    > span {
      margin-bottom: 5vh;
      > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    h5 {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 155%;
    }
    ._text {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0 0 10px 0;
    }
  }
}
