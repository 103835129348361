@import '../../../../styles/variables';
.root {
  display: flex;
  min-height: calc(100vh - 419px);
  justify-content: center;
  @media screen and (max-width: $desktopBreakpoint) {
    max-height: auto !important;
  }

  // ANIM
  ul {
    li {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      transition: opacity 250ms ease-out, transform 300ms ease-out;
    }
  }

  &.in-view {
    ul {
      li {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        @for $i from 1 through 30 {
          &:nth-child(#{$i}n) {
            transition-delay: #{$i * 75}ms;
          }
        }
      }
    }
  }

  // ANIM END

  ._wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @media screen and (max-width: $desktopBreakpoint) {
      overflow: visible;

      h2 {
        margin-top: 20px;
        text-align: center;
        width: 100%;
      }
    }
    @media screen and (min-width: $desktopBreakpoint) {
      width: calc(100% - min(40px, 8vh));
    }
  }
  ._dealers-wrapper {
    overflow-y: auto;
    height: auto;
    position: relative;
    pointer-events: all;
    @media screen and (max-width: $desktopBreakpoint) {
      overflow: visible;
      display: block;
    }
  }
  ._about-box {
    @media screen and (max-width: $desktopBreakpoint) {
      padding: 0 30px;
    }

    > p {
      width: 90%;
    }

    pointer-events: all;
    > div {
      margin-bottom: 60px;
      h3 {
        margin-bottom: 30px;
      }
    }
  }
  ._info-box {
    color: black;
    margin-bottom: 60px;
    > span {
      pointer-events: all;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      @media screen and (max-width: $desktopBreakpoint) {
        margin-top: 20px;
      }
      svg {
        transition: transform 220ms ease-out;
      }
    }

    // > span:hover {
    //   svg {
    //     transform: scale(-1) translateX(5px) !important;
    //   }
    // }
    ._top {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
      background-color: black;
      color: white;
      margin-top: 20px;
      padding: 10px 20px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background-color: black;
    }
    ._information {
      width: 100%;
      padding: 40px 30px;
      display: flex;
      flex-direction: column;
      pointer-events: all;

      > span {
        margin-bottom: 1em;
      }
      h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
      h3 {
        margin-bottom: 1rem;
      }
      a {
        width: fit-content;
        text-decoration: none;
        transition: all 250ms ease-out;
        position: relative;

        &:before,
        &:after {
          left: 0;
          content: '';
          position: absolute;
          bottom: -1px;
          width: 0;
          height: 1px;
          margin: 5px 0 0;
          transition: all 250ms ease-out;
          transition-duration: 0.55s;
          opacity: 0;
          background-color: black;
        }
      }
      a:hover {
        cursor: pointer;
        &:before,
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
      a:first-of-type {
        margin: 0 0 2rem 0;
        color: $lightBlue;
        &:before,
        &:after {
          background-color: $lightBlue;
        }
      }
      a:last-of-type {
        margin-bottom: 2rem;
      }
      a[href^='tel:'] {
        color: black;
        text-decoration: none;
      }
      a[href^='mailto:'] {
        color: black;
        text-decoration: none;
      }

      > span {
        max-width: 200px;
      }

      a:last-of-type {
        margin-bottom: 2rem;
      }
    }
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  color: black;

  @media screen and (min-width: $desktopBreakpoint) {
    overflow-x: hidden;
    overflow-y: auto;

    pointer-events: all;
    //
  }
}
