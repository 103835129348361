@import '../../../styles/variables';

.root {
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 1;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media screen and (max-width: $desktopBreakpoint) {
    transition: margin-bottom 250ms ease-out, height 100ms ease-out;
    min-height: 50vh;
  }

  margin-bottom: 100px;
  height: 50vh;
  height: 70vh;

  &.animEnd {
    .desc {
      opacity: 0;

      &:not(.pageAnim) {
        height: 20vh;
      }
    }
    .cta {
      opacity: 0;
    }

    &.pageAnim {
      transition: height 550ms ease-out;
      height: calc(100vh - 70px);

      > span {
        transition: transform 250ms 750ms ease-in-out;
        transform: translate3d(0, -200px, 0);
      }

      @media screen and (max-width: $desktopBreakpoint) {
        height: 65vh;
      }
    }
  }

  > span {
    background-color: black;
    .desc {
      opacity: 1;
      h4,
      p,
      .cta {
        opacity: 0;
        transform: translate3d(0, -10px, 0);
        transition: transform 350ms ease-out, transform 400ms ease-out;
      }
    }
  }
  &.in-view {
    > span {
      .desc {
        h4,
        p,
        .cta {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          @for $i from 1 through 10 {
            &:nth-child(#{$i}n) {
              transition-delay: #{$i * 150 + 250}ms;
            }
          }
        }
      }
    }

    .cta {
      span,
      svg,
      path {
        opacity: 1;
      }
    }
  }
  > span {
    display: flex;
    position: absolute;
    transition: margin-bottom 250ms 1200ms ease-out;
    max-height: 207px;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 0;
    > span {
      flex: 1;
    }
  }
  color: white;
  h4 {
    margin-top: 0;
    font-size: $fontSize;
  }
  .desc {
    opacity: 0;
    transition: opacity 250ms ease-out;
    flex: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &:not(.pageAnim) {
      transition: all 350ms ease-out;
    }

    max-height: 207px;
    min-height: 40px;

    > span {
      display: flex;
      width: 70%;
      align-items: center;
      justify-content: flex-start;

      > span {
        margin-right: 40px;

        h4,
        p {
          transition: opacity 300ms ease-out;
          opacity: 0;
        }
      }
    }
  }
  .cta {
    transition: opacity 250ms ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span,
    svg,
    path {
      transition: opacity 300ms ease-out;
      opacity: 0;
    }
    > span {
      margin-right: 10px;
    }
  }
}
