@import './colors';

/*
You'll find css custom properties located in `global.scss` in the body tag
*/

// PRINT
$printWidth: 100%;
$maxPrintWidth: 775px;
// GLOBALS
$smallPhoneBreakpoint: 320px;
$largePhoneBreakpoint: 414px;
$desktopBreakpoint: 768px;
$mapDesktopBreakpoint: 900px;
$largeDesktopBreakpoint: 1200px;
$hugeBreakpoint: 1700px;
$padding: 20px;
// FONTS
$fontSize: 1em;
// LAYOUT
$pageWidth: 100vw;
$maxWidth: 1440px;
$gutter: 60px;
$gutterMobile: 20px;
$pageMaxWidth: $maxWidth - $gutter * 2;
$pageGutter: 4.167%;
// SPACING
$componentYPadding: $pageGutter * 2;
$componentXPadding: calc($pageGutter / 2);
$contentColumnCount: 4;
$contentColumnSpace: 1.389%;
$contentColumnSpaceMobile: 2.9%;
// TODO: create a calc for calkcint the
$contentColumnSpaceInPx: 18px; // This is based on width - gutter = 1380px
// $fsContentColumnSpace: calc();
$contentColumnWidth: 4.945%;
$contentColumnWidthMobile: 22.8%;
$contentColumnWidthPlusSpace: $contentColumnWidth + $contentColumnSpace;
$contentColumnWidthMobilePlusSpace: $contentColumnWidthMobile + $contentColumnSpaceMobile;
// size formulas
$content_67_33: 67% 33%;
$content_75_25: 75% 25%;
$content_auto_auto: auto auto;
$content_100: 100%;
$oneColumnWidth: $contentColumnWidthPlusSpace;
$twoColumnWidthNarrow: ($contentColumnWidth * 2 + $contentColumnSpace);
$twoColumnWidth: ($contentColumnWidthPlusSpace * 2);
$threeColumnWidth: ($contentColumnWidthPlusSpace * 2 + $contentColumnWidth);
$fourColumnWidth: ($contentColumnWidthPlusSpace * 3 + $contentColumnWidth);
$fiveColumnWidth: ($contentColumnWidthPlusSpace * 4 + $contentColumnWidth);
$sixColumnWidth: ($contentColumnWidthPlusSpace * 5 + $contentColumnWidth);
$sevenColumnWidth: ($contentColumnWidthPlusSpace * 6 + $contentColumnWidth);
$eightColumnWidth: ($contentColumnWidthPlusSpace * 7 + $contentColumnWidth);
$nineColumnWidth: ($contentColumnWidthPlusSpace * 8 + $contentColumnWidth);
$tenColumnWidth: ($contentColumnWidthPlusSpace * 9 + $contentColumnWidth);
$sixteenColumnWidth: ($contentColumnWidthPlusSpace * 15 + $contentColumnWidth);
$content_30_30-30: $fourColumnWidth $fourColumnWidth $fourColumnWidth;
$content_30_30_30_p: 30% 30% 30%;
@mixin max-plug-width {
  width: calc(100% - $pageGutter);
  max-width: $pageMaxWidth;
}

@mixin container {
  box-sizing: border-box;
  max-width: $pageMaxWidth;
  width: 100%;
  padding-left: var(--spacing-gutter);
  padding-right: var(--spacing-gutter);
  margin-left: auto;
  margin-right: auto;
}

// Tablet -
@mixin full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

@function calculateGridWidth($columns, $space) {
  $sum: ($columns - 1) * $contentColumnWidthPlusSpace + $contentColumnWidth + $space;
  @return $sum;
}

@mixin oneColumnLayout {
  // @include max-plug-width;
  grid-template-columns: auto;
  grid-template-rows: 100%;
}

@mixin twoColumnLayout {
  grid-template-columns: $sixteenColumnWidth;
  grid-template-rows: auto;
}

@mixin fullWidth_45_55 {
  grid-template-columns: minmax(auto, 600px) minmax(50%, auto);
  grid-template-rows: auto;
}

@mixin fullWidth_45_55_columns {
  grid-template-columns: auto;
  grid-template-rows: minmax(auto, 30vh) minmax(50%, auto);
}

@mixin fullWidth_40_60 {
  grid-template-columns: minmax(auto, 500px) minmax(60%, auto);
  grid-template-rows: auto;
}

@mixin fullWidth_60_40 {
  grid-template-columns: minmax(60%, auto) minmax(auto, 500px);
  grid-template-rows: auto;
  column-gap: $contentColumnSpace * 2;
  row-gap: $contentColumnSpace * 4;
}

@mixin twoRowLayout_67_33 {
  grid-template-columns: 100%;
  grid-template-rows: $content_67_33;
}

@mixin twoRowLayout_75_25 {
  grid-template-columns: 100%;
  grid-template-rows: $content_75_25;
}

@mixin treeColumnLayout_30_30_30 {
  grid-template-columns: $content_30_30-30;
  column-gap: $contentColumnSpace;
  grid-template-rows: 100%;
  justify-content: center;
}

@mixin treeRowLayout_33_33_33 {
  grid-template-columns: 100%;
  row-gap: 4%;
  grid-template-rows: $content_30_30_30_p;
  justify-content: center;
  align-items: center;
}

@mixin treeRowLayout_5_4_2_2 {
  // grid-template-columns:
  //   $fiveColumnWidth minmax(220px, $fourColumnWidth) minmax(80px, $twoColumnWidthNarrow)
  //   minmax(80px, $twoColumnWidthNarrow);
  grid-template-columns: $fiveColumnWidth $fourColumnWidth $twoColumnWidthNarrow $twoColumnWidthNarrow;
  grid-template-rows: auto;
  column-gap: $contentColumnSpace;
  align-items: center;
}

@mixin treeRowLayout_8_2_2 {
  grid-template-columns: $nineColumnWidth $twoColumnWidthNarrow $twoColumnWidthNarrow;
  grid-template-rows: auto;
  column-gap: $contentColumnSpace;
  align-items: center;
}

@mixin threeRowAutoLayout {
  grid-template-columns: 100%;
  row-gap: 5%;
  grid-template-rows: auto auto auto;
}

@mixin twoColumnLayout_100_and_10bar_width {
  grid-template-columns: $tenColumnWidth;
  grid-template-rows: $content_100;
  justify-content: center;
}

@mixin max4ColumnLayout {
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  grid-template-rows: auto;
  column-gap: $contentColumnSpace;
}

@mixin max3ColumnLayout_10bar_width {
  grid-template-columns: $fourColumnWidth $fourColumnWidth $fourColumnWidth;
  grid-template-rows: auto;
  column-gap: $contentColumnSpace;
  justify-content: center;
}

@mixin max3RowLayout_100p_width {
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  row-gap: $contentColumnSpace;
  justify-content: center;
}

@mixin twoRowLayout_100_and_16bar_width {
  grid-template-columns:
    minmax(110px, $twoColumnWidthNarrow) minmax(110px, $twoColumnWidthNarrow) minmax(
      auto,
      $tenColumnWidth
    )
    minmax(110px, $twoColumnWidthNarrow);
  grid-template-rows: auto;
  column-gap: $contentColumnSpace;
}

@mixin fiveRowLayout_100_and_16bar_width {
  grid-template-columns: 1fr;
  row-gap: 20px;
}

@mixin twoColumnLayout_8bar_8bar {
  grid-template-columns: $eightColumnWidth $eightColumnWidth;
  grid-template-rows: auto;
  justify-content: center;
}

@mixin twoColumnLayout_8bar_4bar_space {
  grid-template-columns: $eightColumnWidth + $contentColumnSpace * 2 $fourColumnWidth + $contentColumnSpace * 2;
  grid-template-rows: auto;
  justify-content: center;
}

@mixin twoRowLayout_8bar_4bar_space {
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

@mixin twoColumnLayout_10bar_5bar_space {
  grid-template-columns: $eightColumnWidth $sevenColumnWidth + $contentColumnSpace * 2;
  grid-template-rows: auto;
  column-gap: $contentColumnWidthPlusSpace;
}

@mixin twoColumnLayout_8bar_7bar {
  grid-template-columns: $eightColumnWidth $sevenColumnWidth;
  grid-template-rows: auto;
  column-gap: $contentColumnWidth + $contentColumnSpace * 2;
}

// padding
@mixin largePaddingTopMediumBottom {
  padding: min(15vh, 7rem) 0 min(5vh, 2rem) 0;
}

@mixin paddingY {
  padding: min(6vh, 3.5rem) 0;
}

@mixin paddingTop {
  padding-top: min(6vh, 3.5rem);
}

@mixin paddingBottom {
  padding-bottom: min(6vh, 3.5rem);
}

@mixin largePaddingY {
  padding: min(12vh, 7rem) 0;
}

@mixin megaLargePaddingY {
  padding: min(24vh, 9rem) 0;
}

// decoration
@mixin border {
  content: '';
  position: absolute;
  background-color: black;
}

@mixin border-bottom {
  @include border;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
}

@mixin border-left {
  @include border;
  width: 1px;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin border-right {
  @include border;
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
}

// colors
@mixin bgColorSolid {
  &:before {
    content: '';
    position: absolute;
    width: calculateGridWidth(13, 0);
    height: 100%;
    background-color: rgba($lightGray, 1);
    z-index: -1;
  }
}

// @mixin bgColorSolid100 {
//   &:before {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background-color: rgba($lightGray, 1);
//     z-index: -1;
//   }
// }

@mixin bgColorSeeThroughBlack {
  &:before {
    content: '';
    position: absolute;
    width: calculateGridWidth(13, 0);
    height: 100%;
    background-color: rgba(227, 232, 235, 0.2);
    z-index: -1;
  }
}

@mixin breakpoint($px: 0) {
  @media screen and (min-width: $px) {
    @content;
  }
}

@mixin visuallyHidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

// debug
$debugColorRootContainer: rgba(0, 0, 255, 0.1);
$debugColorContentContainer: rgba(0, 0, 0, 0.2);

// font stuff
@mixin smallcaps {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  transform: scale(0.9);
}

@mixin font-xsmall {
  font-size: var(--font-xsmall);
  line-height: var(--font-xsmall-leading);
}

@mixin font-small {
  font-size: var(--font-small);
  line-height: var(--font-small-leading);
}

@mixin font-body {
  font-size: var(--font-body);
  line-height: var(--font-body-leading);
}

@mixin font-large {
  font-size: var(--font-large);
  line-height: var(--font-large-leading);
  font-weight: 300;
}

@mixin font-xlarge {
  font-size: var(--font-xlarge);
  line-height: var(--font-xlarge-leading);
  font-weight: 300;
}

@mixin font-display {
  font-size: var(--font-display);
  line-height: var(--font-display-leading);
}

// GRID
@mixin grid {
  @include container;
  display: grid;
  grid-template-columns: repeat(var(--amount-of-columns), 1fr);
  gap: var(--spacing-gutter);
}
