@import '../../../styles/variables';
.root {
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    padding: 0 0 1.5rem 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 155%;

  }

  ul {
    li {
      padding: 0 0 1.5rem 0;
      font-weight: 300;
      font-size: 16px;
      line-height: 155%;
      list-style-type: disc;
      list-style-position: inside;
      padding-bottom: 12px;
    }
  }

  span, p span {
    text-align: left;
  }
}
